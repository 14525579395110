import {
  AUTH,
  ERROR_AUTH,
  CLEAR_ERROR_AUTH,
  GET_AVATAR,
  UPDATE_AVATAR,
  LOGOUT,
  LOAD,
  REGISTER_USER,
  lOADING,
  GET_MENU
} from '../type';

const initialState: AuthState = {
  auth: false,
  error: '',
  token: '',
  avatar: null,
  user: {},
  loading: false,
  menus: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case lOADING:
      return { ...state, loading: action.payload as boolean };
    case AUTH:
      return {
        ...state,
        token: action.payload as string | null
      };
    case GET_MENU:
      return { ...state, menus: action.payload as any[] };
    case ERROR_AUTH:
      return { ...state, error: action.payload };
    case CLEAR_ERROR_AUTH:
      return { ...state, error: '' };
    case LOGOUT:
      return { ...state, token: '', user: {}, avatar: null, menus: [] };
    case REGISTER_USER:
      return {
        ...state,
        token: action.payload
      };
    case GET_AVATAR:
      return { ...state, avatar: action.payload };
    case LOAD:
      return { ...state, user: action.payload };
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload };
    default:
      return state;
  }
};
