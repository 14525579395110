import { Outlet, Navigate } from 'react-router-dom';
import { getSession } from '../services/session.service';

const PrivateRoute = () => {
  const token = getSession('jwt');

  if (!token) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoute;
