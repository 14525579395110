import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import authReducer from './reducer/auth.reducer';
import layoutReducer from './reducer/layout.reducer';
import carReducer from './reducer/car.reducer';

declare global {
  interface Window {
    REDUX_DEVTOOLS_EXTENSION_COMPOSE?: typeof compose;
  }
}

const composeEnhancers = (typeof window !== 'undefined' && window.REDUX_DEVTOOLS_EXTENSION_COMPOSE) || compose;
// config persist

const persistRootConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  car: carReducer
});
const persistRootReducer = persistReducer(persistRootConfig, rootReducer);
export type AppState = ReturnType<typeof rootReducer>;
export const store = createStore(persistRootReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);
