export const createSession = (key: string, item: any) => {
  return sessionStorage.setItem(key, item);
};

export const getSession = (key: string): string | null => {
  return sessionStorage.getItem(key);
};

export const removeSession = (key: string) => {
  return sessionStorage.removeItem(key);
};
