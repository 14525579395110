import React, { Suspense, lazy, Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MaLoader from '../components/MaLoader';
import PrivateRoute from './PrivateRoute';

const LoginPage = lazy(() => import('../pages/auth/SignIn'));
const FourByFour = lazy(() => import('../pages/errors/FourByFour'));
const DashboardPage = lazy(() => import('../pages/MaDashBoard'));
const LayoutPage = lazy(() => import('../layout/index'));
const RolePage = lazy(() => import('../pages/roles'));
const ListPage = lazy(() => import('../pages/list'));
const ListTablePage = lazy(() => import('../pages/list/tables'));
const ParkCarPage = lazy(() => import('../pages/gestion/ParkCar'));
const AdsPage = lazy(() => import('../pages/gestion/Ads'));
const EditCarPage = lazy(() => import('../pages/gestion/actions/EditCar'));
const BillingPage = lazy(() => import('../pages/gestion/Billing'));
const ContactMobile = lazy(() => import('../pages/gestion/ContactMobilePage'));

const Index: React.FC = () => {
  return (
    <Fragment>
      <Routes>
        {/* public routes */}
        <Route
          path="login"
          element={
            <Suspense fallback={<MaLoader />}>
              <LoginPage />
            </Suspense>
          }
        />
        {/* private the page */}
        <Route element={<PrivateRoute />}>
          <Route
            element={
              <Suspense fallback={<MaLoader />}>
                <LayoutPage />
              </Suspense>
            }
          >
            <Route path="/" element={<Navigate to="dashboard" />} />
            <Route
              path="dashboard"
              index
              element={
                <Suspense fallback={<MaLoader />}>
                  <DashboardPage />
                </Suspense>
              }
            />
            <Route
              path="roles"
              element={
                <Suspense fallback={<MaLoader />}>
                  <RolePage />
                </Suspense>
              }
            />
            <Route path="list">
              <Route
                index
                element={
                  <Suspense fallback={<MaLoader />}>
                    <ListPage />
                  </Suspense>
                }
              />
              <Route
                path=":table"
                element={
                  <Suspense fallback={<MaLoader />}>
                    <ListTablePage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="park-vehicule"
              element={
                <Suspense fallback={<MaLoader />}>
                  <ParkCarPage />
                </Suspense>
              }
            />
            <Route
              path="park-vehicule/create"
              element={
                <Suspense fallback={<MaLoader />}>
                  <EditCarPage />
                </Suspense>
              }
            />
            <Route
              path="park-vehicule/edit/:uuid"
              element={
                <Suspense fallback={<MaLoader />}>
                  <EditCarPage />
                </Suspense>
              }
            />
            <Route
              path="ads"
              element={
                <Suspense fallback={<MaLoader />}>
                  <AdsPage />
                </Suspense>
              }
            />
            <Route
              path="billing"
              element={
                <Suspense fallback={<MaLoader />}>
                  <BillingPage />
                </Suspense>
              }
            />
            <Route
              path="contact-mobile"
              element={
                <Suspense fallback={<MaLoader />}>
                  <ContactMobile />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<MaLoader />}>
              <FourByFour />
            </Suspense>
          }
        />
      </Routes>
    </Fragment>
  );
};

export default Index;
