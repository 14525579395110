import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { store, persistor } from './store/store';
import App from './App';
import ErrorBoundary from './pages/errors/ErrorBundary';
import reportWebVitals from './reportWebVitals';

axios.defaults.baseURL = `${process.env.REACT_APP_API}`;
axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('jwt')}`;

const root = ReactDOM.createRoot(document.getElementById('marco-auto') as HTMLElement);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HashRouter>
            <App />
          </HashRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>
);

reportWebVitals();
