import { ADD_CAR, HYDRATE_CAR } from '../type';

interface StateCar {
  car: any[];
}

const initialState: StateCar = {
  car: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_CAR:
      if (state.car.includes(action.payload)) {
        return {
          ...state,
          car: state.car.filter((id: any) => id !== action.payload)
        };
      } else {
        return {
          ...state,
          car: [...state.car, action.payload]
        };
      }
    case HYDRATE_CAR:
      return {
        ...state,
        car: []
      };
    default:
      return state;
  }
};
