import React, { Fragment, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import MaRoutes from './routes';
import i18n from './i18next';
import { layoutModeTypes } from './components/constants/layout';
import './assets/scss/themes.scss';
import 'remixicon/fonts/remixicon.css';
import 'boxicons/css/boxicons.min.css';
import 'line-awesome/dist/font-awesome-line-awesome/css/all.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  document.documentElement.setAttribute('data-layout', 'vertical');
  document.documentElement.setAttribute('data-sidebar-size', 'lg');
  localStorage.setItem('I18N_LANGUAGE', 'fr');
  i18n.init();
  document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.LIGHTMODE);
  document.documentElement.setAttribute('data-sidebar', layoutModeTypes.LIGHTMODE);
  // useEffect(() => {
  //   if (window.matchMedia) {
  //     const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
  //     if (colorSchemeQuery.matches) {
  //       document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.DARKMODE);
  //       document.documentElement.setAttribute('data-sidebar', layoutModeTypes.DARKMODE);
  //     } else {
  //       document.documentElement.setAttribute('data-layout-mode', layoutModeTypes.LIGHTMODE);
  //       document.documentElement.setAttribute('data-sidebar', layoutModeTypes.LIGHTMODE);
  //     }
  //   }
  // }, []);

  return (
    <Fragment>
      <MaRoutes />
      <ToastContainer closeButton={true} position="top-center" toastClassName="bg-light" bodyClassName="mb-2" />
    </Fragment>
  );
};

export default App;
